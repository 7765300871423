<template>
  <nav class="nav">
    <h1>POP ART FOR MEN</h1>

    <div class="list">
      <div>
        <p>ARTWORKS</p>
        <ul>
          <li @click="itemClick" v-for="(link, i) in arts" :key="i">
            <router-link :to="`/arts/${link.id}`">
              {{ link.id.replace(/_/g, ' ').toUpperCase() }}
            </router-link>
          </li>
        </ul>
      </div>

      <div>
        <p>OTHER</p>
        <ul>
          <li>
            <router-link to="/bio">BIO & CONTACT</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Navigation',
  props: ['links'],
  computed: {
    ...mapState(['arts'])
  },
  methods: {
    itemClick() {
      const arts = document.querySelector('.arts')
      arts.scrollLeft = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  background-color: #f2f2f2;
  width: 250px;
  height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: scroll;

  h1 {
    color: #333;
  }

  p {
    color: rgb(179, 185, 196);
    margin-bottom: 0.8rem;
    font-weight: bold;
    font-size: 0.7rem;
  }

  .list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    padding-top: 2rem;

    div:last-child {
      margin-top: 5rem;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;

    li {
      margin: 0.5rem 0;

      a {
        color: rgb(116, 123, 136);
        font-weight: bold;

        cursor: pointer;
        transition: all 150ms ease-in-out;
        display: block;
        text-decoration: none;

        &:hover {
          color: darken(rgb(116, 123, 136), 30%);
        }

        &.router-link-active {
          color: #228b22;
        }
      }
    }
  }
}
</style>

<template>
  <div id="app" :class="device()">
    <Nav v-if="device() === 'desktop'" :links="allLinks" />
    <MobileNav v-if="device() === 'mobile'" :links="allLinks" />

    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/Nav'
import MobileNav from '@/components/MobileNav'
import deviceMixin from '@/mixins/deviceMixin'

export default {
  name: 'App',
  mixins: [deviceMixin],
  components: {
    Nav,
    MobileNav
  },
  computed: {
    ...mapState(['allLinks'])
  },
  mounted() {
    this.$store.dispatch('appStart')
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  display: flex;

  &.mobile {
    height: 100%;
    flex-direction: column-reverse;
  }
}
</style>

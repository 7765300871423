export default {
  methods: {
    device() {
      if (
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return 'mobile'
      } else {
        return 'desktop'
      }
    }
  }
}
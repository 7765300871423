import Vue from 'vue'
import Vuex from 'vuex'
import { db } from '@/firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    arts: []
  },
  getters: {
    getArtsByName: state => id => {
      return state.arts.find(art => art.id === id)
    }
  },
  mutations: {
    SAVE_ARTS(state, arts) {
      state.arts = arts
    }
  },
  actions: {
    appStart({ commit }) {
      db.collection('artworks')
        .orderBy('sortIndex')
        .get()
        .then(snap => {
          commit('SAVE_ARTS', snap.docs.map(doc => {
            let mapped = doc.data()
            mapped.id = doc.id
            return mapped
          }))
        })
    }
  }
})

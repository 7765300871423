<template>
  <nav>
    <img :src="require('@/assets/icons/left-arrow.svg')" alt="Left arrow." />

    <ul>
      <li v-for="(link, i) in arts" :key="i" @click="onItemClick">
        <router-link :to="`/arts/${link.id}`">
          {{ link.id.replace(/_/g, ' ').toUpperCase() }}
        </router-link>
      </li>

      <li @click="onItemClick">
        <router-link to="/bio">BIO & CONTACT</router-link>
      </li>
    </ul>

    <img :src="require('@/assets/icons/right-arrow.svg')" alt="Right arrow." />
  </nav>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'mobile-nav',
  props: ['links'],
  computed: {
    ...mapState(['arts'])
  },
  methods: {
    onItemClick() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  z-index: 99;
  border-top: 1px solid rgb(224, 224, 224);
  display: flex;
  align-items: center;

  img {
    width: 1.2rem;
    margin: 0 0.5rem;

    &:first-child {
      margin-right: 1.2rem;
    }

    &:last-child {
      margin-left: 1.2rem;
    }
  }

  ul {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    list-style: none;
    width: 100%;
    height: 4.5rem;

    li {
      flex-shrink: 0;
      margin-right: 1.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        border-left: 1px solid rgb(224, 224, 224);
        padding-left: 1.5rem;
      }

      a {
        text-decoration: none;
        color: rgb(167, 167, 167);
        font-weight: bold;

        &.router-link-exact-active {
          color: #228b22;
        }
      }
    }
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/arts/:art',
    name: 'arts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "arts" */ '../views/Arts.vue')
  },
  {
    path: '/bio',
    name: 'bio',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bio" */ '../views/Bio.vue')
  },
  {
    path: '/',
    redirect: {
      name: 'arts',
      params: {
        art: 'super_heroes'
      }
    }
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
